jQuery(function ($) {
  tinymce.init({
    selector: 'textarea#textarea-wysiwyg',
    plugins: 'lists, link, code',
    toolbar: 'bold italic underline alignleft alignright aligncenter alignjustify  bullist numlist forecolor backcolor  | link | code',
    menubar: false,
    setup: (editor) => {
      // Apply the focus effect
      editor.on("init", () => {
        editor.getContainer().style.transition = "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out";
      });
      editor.on("focus", () => { (editor.getContainer().style.boxShadow = "0 0 0 .2rem rgba(0, 123, 255, .25)"),
        (editor.getContainer().style.borderColor = "#80bdff");
      });
      editor.on("blur", () => {
        (editor.getContainer().style.boxShadow = ""),
          (editor.getContainer().style.borderColor = "");
      });
    },
  });

  
});

