$(function () {
  'use strict';

  $('.grafich__country').find('.grf-bar').each(function () {
    var h = $(this).attr('data-value');
    var hend = 100 * h / referece
    $(this).css('height', hend + '%')
  });


  $('.select-arrow').on('change', function () {
    var url = $(this).val();
    if (url) {
      window.location = url;
    }
    return false;
  });


  //material contact form animation
  $('.form-bills').find('.form-control').each(function () {
    var targetItem = $(this).parent();
    if ($(this).val()) {
      $(targetItem).find('label').css({
        'top': '5px',
        'fontSize': '14px'
      });
    }
  })
  $('.form-bills').find('.form-control').focus(function () {
    $(this).parent('.input-block').addClass('focus');
    $(this).parent().find('label').animate({
      'top': '5px',
      'fontSize': '14px'
    }, 300);
  })
  $('.form-bills').find('.form-control').blur(function () {
    if ($(this).val().length == 0) {
      $(this).parent('.input-block').removeClass('focus');
      $(this).parent().find('label').animate({
        'top': '35px',
        'fontSize': '18px'
      }, 300);
    }
  })


  $('.form-bills').find('.form-control__date').focus(function () {
    $(this).parent('.input-block__date').addClass('focus');
  })
  $('.form-bills').find('.form-control__date').blur(function () {
    if ($(this).val().length == 0) {
      $(this).parent('.input-block__date').removeClass('focus');
    }
  })


  function setSelectionRange(input, selectionStart, selectionEnd) {
    if (input.setSelectionRange) {
      input.focus();
      input.setSelectionRange(selectionStart, selectionEnd);
    } else if (input.createTextRange) {
      var range = input.createTextRange();
      range.collapse(true);
      range.moveEnd('character', selectionEnd);
      range.moveStart('character', selectionStart);
      range.select();
    }
  }

  function setCaretToPos(input, pos) {
    setSelectionRange(input, pos, pos);
  }


  $("#money").click(function () {
    var inputLength = $("#money").val().length;
    setCaretToPos($("#money")[0], inputLength)
  });

  var options = {
    onKeyPress: function (cep, e, field, options) {
      if (cep.length <= 6) {

        var inputVal = parseFloat(cep);
        jQuery('#money').val(inputVal.toFixed(2));
      }

      // setCaretToPos(jQuery('#money')[0], 4);

      var masks = ['#,##0.00', '0.00'];
      mask = (cep == 0) ? masks[1] : masks[0];
      $('#money').mask(mask, options);
    },
    reverse: true
  };


  document.querySelectorAll('.uploader input[type="file"]').forEach((el) =>
    el.addEventListener("change", (ev) => {
      if (ev.target.files && ev.target.files.length) {
        const text = Array.from(ev.target.files)
          .map((x) => x.name)
          .join(", ");
        el.parentNode.querySelector(".placeholder").innerText = text;
      }
    })
  );

  var num = $('.pagination').find('li').length;
  if (num <= 1) {
    $('.pagination').addClass('d-none')
  }

  $('.menu-mobile').click(function () {
    $('.mobile-mode').toggleClass('d-block');
    $('.mobile-mode').toggleClass('d-none');
    $('.menu-button').toggleClass('d-none');
    $('.close-menu').toggleClass('d-none');
    $('.close-menu').toggleClass('d-block');
  });

  $('.open-filter').click(function () {
    $(this).next('.filter-content').toggleClass('activ');
  });
  $(document).on('click', function (e) {
    if (!$(e.target).closest('.filter-content').length && !$(e.target).hasClass('open-filter')) {
      $('.filter-content').removeClass('activ');
    }
  });

  $('.open-col').click(function () {
    $('.fixed-col').toggleClass('activ');
    $('.backgroung-col').toggleClass('d-none');
    $('html').toggleClass('fixed-scroll');
  });

  $('.edit-group').click(function () {
    var id = $(this).attr('data-id');
    var name = $(this).attr('data-name');
    $('#id_group').val(id);
    $('#editgroupname').val(name);
  });

  $('.edit-page').click(function () {
    var id = $(this).attr('data-id');
    var dataidgroup = $(this).attr('data-id-group');
    var datapage = $(this).attr('data-page');
    $('#id_page').val(id);
    $('select#idgroup option[value=' + dataidgroup + ']').attr("selected", "selected");
    $('select#page option[value=' + datapage + ']').attr("selected", "selected");
  });

  $('.open-menu').click(function () {
    $(this).next('.submenu').toggle('fast');
    $(this).toggleClass('activ');
  });

  $('.openload').click(function () {
    $('.iconload').removeClass('d-none');
    setTimeout(function () {
      $('.iconload').addClass('d-none');
    }, 5000);
  });

  $(document).ready(function () {
    $(window).scroll(function () {
      var scroll = $(window).scrollTop();
      if (scroll > 100) {
          $(".contas-header").addClass("activ");
      } else {
          $(".contas-header").removeClass("activ");
      }
    });
  });

  $('.icofont-password .icon-eye').click(function () {

    $(this).parent('.icofont-password').toggleClass('activ');

  });

});
